section.animated {
    &.shown {
        transition: opacity .5s;
        position: relative;
        opacity: 1;
    }

    &.hidden {
        transition: opacity .5s;
        position: relative;
        opacity: 0;
    }
}

// Small devices (landscape phones, 767px and down)
@media (max-width: 767px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}