.display-blog,
.display-vlog {
    .return-header {
        margin-bottom: 15px;
        float: right;

        a {
            text-decoration: none;
            border-bottom: 1px solid #0d6efd;
            padding-bottom: 3px;
        }
    }

    img {
        max-width: 100%;
    }

    padding: 30px 15px;

    .blog-main,
    .vlog-main {
        clear: both;

        .title {
            color: #7bb2b9;
            font-weight: bold;
            font-size: 14pt;
        }

        .vlog-item {
            font-size: 10pt;
            width: 60%;
            border-bottom: 1px solid #727272;
            padding: 10px 0px;

        }
    }

    .blog-gutter,
    .vlog-gutter {
        margin: 15px;
        padding: 15px;
        background-color: #f3f3f3;

        .title {
            font-weight: bold;
            font-size: 16pt;
        }
    }
}

button.item-btn {
    border-radius: 0 0 25px 0 !important;
}