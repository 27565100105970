nav.navbar {
    background-color: #4b5861 !important;

    img.app-logo {
        height: 40px;
        margin: 35px;
        transition: 0.5s;
    }

    &.scrolled {
        img.app-logo {
            height: 25px;
            margin: 15px;
            transition: 0.5s;
        }

        .navbar-nav {
            .nav-link {
                font-size: 10pt;
                transition: 0.5s;
            }
        }
    }
}

.navbar-dark {
    .navbar-nav {
        .nav-link {
            margin: 0 20px;
            font-family: "CarroisGothic";
            font-size: 14pt;
            font-weight: bold;
            color: #ffffff;
            transition: 0.5s;

            &:hover,
            &:active,
            &:focus {
                color: #eac76e;
            }

            &.active {
                font-size: 14pt;
                font-weight: bold;
                color: #eac76e;

                &:visited {
                    color: #eac76e;
                }
            }
        }
    }

    .navbar-toggler,
    .navbar-toggler:focus {
        border: none;
        box-shadow: none;
    }
}

.btn-outline-success {
    color: #eac76e;
    border-color: #eac76e;
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    box-shadow: 0 0 0 0.25rem rgba(234, 199, 110, 50%);
}

.btn-outline-success:hover,
.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    color: #000;
    background-color: #eac76e;
    border-color: #eac76e;
}

.btn:focus {
    border: none;
    box-shadow: none;
}

.offcanvas {
    background-color: #4e5860 !important;

    .nav-link {
        color: #FFFFFF;
    }
}

.offcanvas-title {
    img.app-logo {
        height: 40px;
        margin: 35px;
        transition: 0.5s;
    }
}

.offcanvas-header {

    .btn-close,
    .btn-close:focus {
        color: #ffffff;
        background: none;
        box-shadow: none;
        opacity: 1;
    }
}