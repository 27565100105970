section#headline {
    width: 100%;
    height: calc(100vh - 150px);

    .title.white {
        text-shadow: 2px 2px 2px #555;
    }

    .get-started {
        border-color: #5ebbcd;
        background-color: #5ebbcd;
        color: white;
        z-index: 1000;
        border-radius: 15px !important;

        width: 250px;

        &:hover {
            background-color: white;
            color: #5ebbcd;
        }
    }
}

section#vision {
    height: 450px;

    width: 100%;
    background-color: #ffffff;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #85b7be;
        font-weight: bold;
    }
}

.vision-row {
    height: 450px;
    overflow: hidden;
}

.vision-header {
    height: 600px;
    width: 400px;
    background-color: lighten(#eac76e, 10%);
    margin-top: -50px;
    margin-right: 150px;
    border-radius: 0 300px 300px 0;
    padding: 150px 0 150px 50px;

    h1 {
        font-size: 60pt;
        font-family: "CarroisGothic";
        font-weight: bold;
    }
}

.vision-content {
    padding-top: 140px;
    padding-right: 40px;

    p {
        font-size: 18pt;
        color: #707070;
    }
}

// Small devices (landscape phones, 767px and down)
@media (max-width: 767px) {
    section#headline .headline {
        font-size: 48pt;
    }

    section#vision {
        height: unset;
    }

    .vision-header {
        height: unset;
        width: 100%;
        margin-top: 0;
        margin-right: 0;
        padding: 0px;
        background-color: lighten(#eac76e, 10%);

        border-radius: 0;

        h1 {
            margin-top: 0;
            padding: 10px 15px;
        }
    }

    .vision-content {
        padding: 10px 15px;
    }

    .vision-row {
        height: unset;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}