footer {
    font-family: Rubix, sans-serif;
    font-size: 13px;
    background-color: #4b5861;
    color: rgb(140, 149, 154);
    height: 500px;
    padding: 50px;
    h1 {
        font-size: 72pt;
    }

    .logo {
        width: 110px;
    }

    .footer-headline {
        color: #fff;
        font-weight: 500;
        margin-top: 10px;
    }

    ul {
        list-style: none;
    }

    .section-header {
        color: #fff;
        font-weight: 500;
        font-size: 15px;
        text-transform: uppercase;
        line-height: 18px;;
    }

    a,
    a:hover,
    a:visited,
    a:focus {
        color: rgb(140, 149, 154);
        text-decoration: none;
    }
}
