section#blog_summary {
    background-color: #4b5861;
    color: #ffffff;
    display: flex;

    #header {
        text-align: center;

        span.title,
        span.mobile-title {
            padding: 100px 0;
            color: #eac76e;
            font-size: 72pt;
            line-height: normal;
        }

        span.mobile-title {
            display: none;
        }

        .btn-outline-primary {
            background-color: #e4bc5c;
            border-color: #7a725e;
            color: #7a725e;
            padding: 10px 15px;
        }
    }

    .sticky-section.sticky-header {
        background-color: #4b5861;
        width: 40%;
        padding: 50px;
        float: left;
    }

    .peer-section {
        background-color: #4b5861;
        width: 60%;
        float: right;
    }
}

#blog_list {
    padding-top: 25px;
    padding-left: 100px;

    display: block;

    z-index: 50;

    padding-top: 80px;
    padding-bottom: 30px;
}

.blog-preview,
.vlog-preview {
    margin-left: 10px;
    margin-right: 30px;
    padding: 15px 0px;
    background-color: #ffffff;
    color: #707070;
    border: 1px solid #4b5861;
    border-radius: 10px;
    z-index: 100;

    .blog-icon {
        img.icon {
            margin: 0px auto;
            width: 100%;
            height: 100%;
            max-width: 100px;
            max-height: 100px;
            border-radius: 50%;
        }
    }

    .title {
        font-size: 16pt;
        font-weight: bold;
        line-height: normal;
    }

    p.excerpt {
        margin: 10px 0;
    }

    p.snippet {
        position: relative;
        max-height: 80px;
        line-height: 21px;
        overflow-y: hidden;
    }

    p.snippet:after {
        content: '...';
        position: relative;
        right: 0;
        bottom: 0;
    }

    .btn-outline-primary {
        border-color: #76b9bf;
        color: #76b9bf;

        &:hover {
            background-color: #76b9bf;
            color: white;
        }
    }
}

.section {
    border: none;
}

.blog-row {
    min-width: 100%;
    height: 400px;
    display: flex;
    overflow-x: auto;
    flex-direction: row;
}

.blog-row-item {
    margin: 2px;
}

// Small devices (landscape phones, 767px and down)
@media (max-width: 767px) {
    section#blog_summary #header span.title {
        display: none;
        font-size: 32pt;
    }

    section#blog_summary #header span.mobile-title {
        display: block;
        font-size: 32pt;
        padding: 20px 0;
    }

    section#blog_summary {
        .sticky-section.sticky-header {
            width: 100%;
            background-color: #4b5861;
        }

        .peer-section {
            position: unset;
            width: 100%;
        }
    }

    #blog_list {
        padding-left: 10px;
        padding-top: 0px;

        .blog-preview {
            margin-top: 50px !important;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 991px) {
    section#blog_summary #header span.title {
        font-size: 42pt;
    }

    #blog_list {
        padding-left: 10px;
        padding-top: 0px;

        .blog-preview {
            margin-top: 50px !important;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}