#mainPage {
  background-color: #4b5861;
  margin-top: 135px;
}

#main_content {
  margin-top: 135px;
  background-color: #ffffff;
}

.sticky-header {
  position: sticky;
  top: 65px;
  z-index: 10;
}