#project_summary {
    background-color: #76b9bf;
    color: #ffffff;
    padding-bottom: 100px;
    width: 100%;
    z-index: 60;

    .project-header {
        padding: 50px 0 20px;
        background-color: #76b9bf;

        h1 {
            font-family: "CarroisGothic";
            font-size: 48pt;
            font-weight: bold;
            color: white;
        }

        h3 {
            font-family: "CarroisGothic";
            color: white;
        }
    }

    .title {
        font-family: "CarroisGothic";
        font-size: 72pt;
    }

    .subtitle {
        margin-top: -40px;
        font-family: "CarroisGothic";
        font-size: 40pt;
    }

    #project_list {
        .project-preview {
            margin: 30px auto;
            padding: 30px 15px;
            background-color: #ffffff;
            color: #707070;
            border-radius: 10px;

            .project-banner {
                width: 200px;

                .project-icon {
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                }

                .title {
                    margin-top: 8px;
                    font-size: 14pt;
                    line-height: 14pt;
                }
            }

            .description {
                font-size: 14pt;

                display: -webkit-box;
                -webkit-line-clamp: 9;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            &.stacked {
                margin: 10px 10px;
                padding: 10px 10px;

                .project-summary {
                    margin: 0;
                    border: 1px solid #4b5861;
                    border-radius: 10px;

                    .column {
                        margin: 15px 10px;
                    }

                    .description {
                        min-height: 250px;
                    }
                }
            }
        }
    }

    .btn-outline-primary {
        background-color: #e4bc5c;
        border-color: #7a725e;
        color: #7a725e;
        padding: 10px 15px;
    }

    .get-started {
        background-color: transparent;
        border-color: white;
        color: white;
        margin-bottom: 25px;

        &:hover {
            background-color: white;
            color: #76b9bf;
        }
    }
}

#project_header {
    height: 500px;
    min-height: 375px;
    background-color: #f8f8f8;
    overflow: hidden;

    .header-title {
        margin-top: -50px;
        padding: 100px 0 0 0;

        height: 575px;
        min-height: 425px;
        width: 625px;
        float: left;
        background-color: #fff0cd;
        color: #4c5861;
        border-radius: 0 50vh 50vh 0;

        h1.title {
            position: relative;
            top: -20px;
            left: 100px;
            margin: auto 0;
            height: 100%;
            text-indent: -32px;
            padding-left: 32px;
        }
    }

    .header-art {
        margin-top: 5px;
        margin-right: 15px;
        padding: 15px;
        height: 490px;
        width: 490px;
        min-height: 350px;
        min-width: 350px;
        float: right;

        .row {
            height: calc(490px / 6);
            min-height: calc(350px / 6);
        }

        .project-dot {
            transition: background 1s ease-out;
            margin: 10px 0;
            height: 42px;
            width: 42px;
            border: 1px solid #98ADC1;
            border-radius: 50%;

            &.darkened {
                border: 1px solid #98ADC1;
            }
        }
    }
}

// Small devices (landscape phones, 767px and down)
@media (max-width: 767px) {
    #project_summary .project-header h1 {
        font-size: 36pt;
    }

    #project_summary .project-header h3 {
        font-size: 18pt;
    }

    #project_header {
        height: 38vh;
        background-color: #fff0cd;

        .header-title {
            h1.title {
                font-size: 50pt;
                top: 80px;
                left: 40px;
            }
        }

        .header-art {
            height: 30vh;
            width: 30vh;

            .row {
                height: calc(30vh / 6);
            }

            .project-dot {
                height: 32px;
                width: 32px;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}