#primary_image {
    margin-top: 150px;
}

header {
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
}

header .container {
    padding-top: 7rem;
    padding-bottom: 7rem;
    position: relative;
}

header h1 {
    font-size: 3.6rem;
    text-transform: uppercase;
}

header .lead {
    font-size: 1.35rem;
}

header.has-image {
    color: #eee;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    height: 500px;
}

header.has-image .dimmer {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.45;
}

.inset {
    position: absolute;
    inset: 0;
}
.title.white {
    text-shadow: 2px 2px 2px #555;
}
.white {
    color: white;
}
.gray {
    color: #446;
}

.full {
    height: calc(100vh - 150px);
}

.gradient {
    background: linear-gradient(rgba(14, 62, 151, 0.25) 20%, black);
}
