@font-face {
    font-family: "CarroisGothic";
    src: local("CarroisGothic"), url(./assets/fonts/CarroisGothic-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "GothamBook";
    src: local("GothamBook"), url(./assets/fonts/GothamBook.ttf) format("truetype");
}

@font-face {
    font-family: "Rubik-Bold";
    src: local("Rubik-Bold"), url(./assets/fonts/Rubik-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Rubik-Light";
    src: local("Rubik-Light"), url(./assets/fonts/Rubik-Light.ttf) format("truetype");
}

// body {
//     margin: 0;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

// code {
//     font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
// }

html,
body {
    padding: 0;
    margin: 0;
    background: #000;
    color: #707070;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

    background-color: #4b5861 !important;
}

.full {
    height: 100vh;
}

.flex {
    display: flex;
}

.center {
    justify-content: center;
    text-align: center;
}

.vcenter {
    align-items: center;
}

.left {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.right {
    align-items: flex-end;
    justify-content: flex-end;
    text-align: flex-end;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: flex-end;
}

// .headline {
//     font-weight: 100;
//     font-size: 10vw;
// }

// .white {
//     color: white;
// }

// .gray {
//     color: #446;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
    &.underline {
        padding-bottom: 5px;
        border-bottom: 1px solid rgb(75, 88, 97);
    }
}

.btn {
    border-radius: 10px !important;
}

h1.title {
    font-family: "CarroisGothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 72pt;
}